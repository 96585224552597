define("ui/instance-initializers/intl", ["exports", "ui/utils/intl/missing-message"], function (_exports, _missingMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(instance) {
    var intl = instance.lookup('service:intl');
    var adapter = intl.get('adapter');
    adapter.reopen({
      _lookup: adapter.lookup,
      lookup: function lookup(locales, key) {
        locales = Ember.makeArray(locales || Ember.get(this, 'locale'));

        if (locales[0] === 'none') {
          return (0, _missingMessage.default)(key, locales);
        } else if (key) {
          return this._lookup(locales, key);
        } else {
          return this._lookup(locales, 'generic.missing');
        }
      }
    }); // @TODO use regular t with htmlSafe instead

    intl.reopen({
      tHtml: function tHtml(key) {
        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        var options = args[0];
        var translation = this.findTranslationByKey(key, options && options.locale);
        return this.formatHtmlMessage.apply(this, [translation].concat(args));
      }
    });
  }

  var _default = {
    name: 'intl',
    after: 'ember-intl',
    initialize: initialize
  };
  _exports.default = _default;
});