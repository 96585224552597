define("ui/application/route", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    access: Ember.inject.service(),
    cookies: Ember.inject.service(),
    language: Ember.inject.service('user-language'),
    modal: Ember.inject.service(),
    prefs: Ember.inject.service(),
    settings: Ember.inject.service(),
    previousParams: null,
    previousRoute: null,
    loadingShown: false,
    loadingId: 0,
    hideTimer: null,
    previousLang: null,
    shortcuts: {
      'shift+l': 'langToggle'
    },
    beforeModel: function beforeModel() {
      this.updateWindowTitle();
      var agent = window.navigator.userAgent.toLowerCase();

      if (agent.indexOf('msie ') >= 0 || agent.indexOf('trident/') >= 0) {
        this.replaceWith('ie');
        return;
      } // Find out if auth is enabled


      return Ember.get(this, 'access').detect();
    },
    model: function model(params, transition) {
      var _this = this;

      transition.finally(function () {
        Ember.get(_this, 'language').initLanguage();

        _this.controllerFor('application').setProperties({
          state: null,
          code: null,
          error_description: null
        });
      });

      if (params.isPopup) {
        this.controllerFor('application').set('isPopup', true);
      }
    },
    actions: {
      loading: function loading(transition) {
        var _this2 = this;

        this.incrementProperty('loadingId');
        var id = Ember.get(this, 'loadingId');
        Ember.run.cancel(Ember.get(this, 'hideTimer')); // console.log('Loading', id);

        if (!Ember.get(this, 'loadingShown')) {
          Ember.set(this, 'loadingShown', true); // console.log('Loading Show', id);

          Ember.run.schedule('afterRender', function () {
            $('#loading-underlay').stop().show().fadeIn({
              // eslint-disable-line
              duration: 100,
              queue: false,
              easing: 'linear',
              complete: Ember.run.schedule('afterRender', function () {
                // eslint-disable-line
                $('#loading-overlay').stop().show().fadeIn({
                  duration: 200,
                  queue: false,
                  easing: 'linear'
                }); // eslint-disable-line
              })
            });
          });
        }

        transition.finally(function () {
          var self = _this2;

          function hide() {
            // console.log('Loading hide', id);
            Ember.set(self, 'loadingShown', false);
            Ember.run.schedule('afterRender', function () {
              $('#loading-overlay').stop().fadeOut({
                // eslint-disable-line
                duration: 200,
                queue: false,
                easing: 'linear',
                complete: Ember.run.schedule('afterRender', function () {
                  // eslint-disable-line
                  $('#loading-underlay').stop().fadeOut({
                    duration: 100,
                    queue: false,
                    easing: 'linear'
                  }); // eslint-disable-line
                })
              });
            });
          }

          if (Ember.get(_this2, 'loadingId') === id) {
            if (transition.isAborted) {
              // console.log('Loading aborted', id, get(this, 'loadingId'));
              Ember.set(_this2, 'hideTimer', Ember.run.next(hide));
            } else {
              // console.log('Loading finished', id, get(this, 'loadingId'));
              hide();
            }
          }
        });
        return true;
      },
      error: function error(err, transition) {
        /* if we dont abort the transition we'll call the model calls again and fail transition correctly*/
        transition.abort();
        var status = parseInt(err.status, 10);

        if (err && [401, 403].includes(status)) {
          this.send('logout', transition);
          return;
        }

        this.controllerFor('application').set('error', err);
        this.transitionTo('failWhale'); // console.log('Application Error', (err ? err.stack : undefined));
      },
      goToPrevious: function goToPrevious(def) {
        this.goToPrevious(def);
      },
      finishLogin: function finishLogin() {
        this.finishLogin();
      },
      logout: function logout(transition, errorMsg) {
        var _this3 = this;

        var session = Ember.get(this, 'session');
        var access = Ember.get(this, 'access');
        access.clearToken().finally(function () {
          Ember.get(_this3, 'tab-session').clear();
          Ember.set(_this3, "session.".concat(_constants.default.SESSION.CONTAINER_ROUTE), undefined);
          Ember.set(_this3, "session.".concat(_constants.default.SESSION.ISTIO_ROUTE), undefined);
          Ember.set(_this3, "session.".concat(_constants.default.SESSION.CLUSTER_ROUTE), undefined);
          Ember.set(_this3, "session.".concat(_constants.default.SESSION.PROJECT_ROUTE), undefined);

          if (transition && !session.get(_constants.default.SESSION.BACK_TO)) {
            session.set(_constants.default.SESSION.BACK_TO, window.location.href);
          }

          if (Ember.get(_this3, 'modal.modalVisible')) {
            Ember.get(_this3, 'modal').toggleModal();
          }

          var params = {
            queryParams: {}
          };

          if (errorMsg) {
            params.queryParams.errorMsg = errorMsg;
          }

          _this3.transitionTo('login', params);
        });
      },
      langToggle: function langToggle() {
        var svc = Ember.get(this, 'language');
        var cur = svc.getLocale();

        if (cur === 'none') {
          svc.sideLoadLanguage(Ember.get(this, 'previousLang') || 'en-us');
        } else {
          Ember.set(this, 'previousLang', cur);
          svc.sideLoadLanguage('none');
        }
      }
    },
    updateWindowTitle: function () {
      document.title = Ember.get(this, 'settings.appName');
    }.observes('settings.appName'),
    finishLogin: function finishLogin() {
      var session = Ember.get(this, 'session');
      var backTo = session.get(_constants.default.SESSION.BACK_TO);
      session.set(_constants.default.SESSION.BACK_TO, undefined);

      if (backTo) {
        // console.log('Going back to', backTo);
        window.location.href = backTo;
      } else {
        this.replaceWith('authenticated');
      }
    }
  });

  _exports.default = _default;
});